<template>
  <reclamation-filtered-and-listing
    title="MY_COMPLAINT"
    :button-new-reclamation="true"
    :display-filter="true"
    :show-overlay="showOverlay"
    :default-status="defaultStatus"
  />
</template>

<script>
import reclamationFilteredAndListing from './filteredListing.vue'

export default {
  components: {
    reclamationFilteredAndListing,
  },
  data() {
    return {
      allReclamations: [],
      showOverlay: false,
      defaultStatus: { value: 'PROCESSING', label: 'PROCESSING' },

    }
  },
  watch: {
    $route(to) {
      const condition = []
      this.defaultStatus = { value: to.params.filter, label: to.params.filter }
      condition.status = to.params.filter
      this.$store.dispatch('reclamations/filter', condition).then(() => { this.showOverlay = false })
    },
  },
  mounted() {
    const condition = []
    if (this.$route.params && this.$route.params.filter) {
      condition.status = this.$route.params.filter
      this.defaultStatus = { value: this.$route.params.filter, label: this.$route.params.filter }
    } else {
      condition.status = 'PROCESSING'
    }
    this.showOverlay = true
    this.$store.dispatch('reclamations/fetchMyReclamation')
      .then(() => {
        this.$store.dispatch('reclamations/filter', condition).then(() => { this.showOverlay = false })
      })
      .catch(() => {
        this.showOverlay = false
        this.$router.push('/pages/error-404')
      })
  },
}
</script>
